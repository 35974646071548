import React, { useState } from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import { ArrowRight } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image";

import queryString from 'query-string'

import Exercise from '../../static/assets/Exercise Program Beige.png'
import Meal from '../../static/assets/Meal Plan Green.png'
import Private from '../../static/assets/Private Coaching Beige.png'

const About = () => {

const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}
const [featured, setFeatured] = useState(parsed.service || 'private-coaching')


  return <>
    <Helmet>
      <title>Services Detail - Enriched</title>
      <meta name="description" content="Coaching & Consulting" />
    </Helmet>
    <div className="detail-page">
      <div className="new-services">
      <h1>about our services</h1>
      </div>
      <div className="new-header">
        <div className="header-text">
          Customized to meet your unique <span className="large">nutrition</span> & <span className="large">wellness</span> needs.
        </div>
        <div className="img-div">
          <StaticImage
                  src='../../static/assets/Services Detail Header.jpg'
                  alt="Our Services"
                />
        </div>
      </div>
      <h2>Packages</h2>
      <div className="details-top">
          <div className="deet">
            <div className="front">
              <h4>Let's Get Started</h4>
              <div className="text">
                <p>1 Assessment</p>
                <p>4-Week Meal Plan</p>
              </div>
                <div className="bottom">
                  <p className="strong">$425</p>
                  <p>($474 value)</p>
                  <a href="https://buy.stripe.com/7sI4jj1oYgnMgRW3cg" 
                  target="blank">Purchase</a>
                </div>
            </div>
          </div>
          <div className="deet">
            <div className="front">
              <h4>Keep Me Accountable</h4>
              <div className="text">
                <p>1 Assessment</p>
                <p>3 Private Coaching Sessions</p>
              </div>
                <div className="bottom">
                  <p className="strong">$730</p>
                  <p>($815 value)</p>
                  <a href="https://buy.stripe.com/3cseXX8Rqb3s59ebIN" 
                  target="blank">Purchase</a>
                </div>
            </div>
          </div>
          <div className="deet">
              <h4>I'm All In!</h4>
              <div className="text">
                <p>1 Assessment</p>
                <p>4-Week Meal Plan</p>
                <p>3 Private Coaching Sessions</p>
            </div>
                <div className="bottom">
                  <p className="strong">$900</p>
                  <p>($1,014 value)</p>
                  <a href="https://buy.stripe.com/8wM3ffebK7Rg6di28e" 
                  target="blank">Purchase</a>
                </div>
          </div>
      </div>
      <h2>Pricing & Information</h2>
      <div className="details-top mid">
        <div className="deet">
          <h4>meal plan</h4>
          <img src={Meal} height="120px" alt="Meal Plan" />
          <div className="bottom">
                  <p>4-Week Plan</p>
                  <p className="strong">$199</p>
                  <a href="https://buy.stripe.com/6oE3ff0kU2wWbxC5kk" 
                  target="blank" className="livesite-payment">Purchase</a>
                </div>
        </div>
        <div className="deet">
          <h4>exercise program</h4>
          <img src={Exercise} height="120px" alt="Exercise Program" />
                <div className="bottom">
                  <p>4-Week Plan</p>
                  <p className="strong">$99</p>
                  <a href="https://buy.stripe.com/9AQeXX0kUgnM9pu9AB" 
                  target="blank" className="livesite-payment">Purchase</a>
                </div>
        </div>
        <div className="deet">
          <h4>dietary assessment</h4>
          <img src={Private} height="120px" alt="Dietary Assessment" />
          <div className="bottom">
                  <p>Assessment & Recommendations</p>
                  <p className="strong">$275</p>
                  <a href="https://buy.stripe.com/eVa8zz1oY8Vk59e003" 
                  target="blank" className="livesite-schedule">Purchase</a>
                </div>
        </div>
      </div>
      <div className="coaching new-serv">
        <h3>Private Coaching</h3>
        <div className="cards">
          <div className="card">
            <div className="number">1</div>
            <h4>Plan</h4>
            <p><span className="strong">Create your personalized Action Plan.</span> We will assess where you are today, 
            identify where you want to go and why, and collaborate on the best way to get there.</p>
            <h5>Includes:</h5>
            <h6>Assessment</h6>
            <div className="bullet">• Health history questionnaire</div>
            <div className="bullet">• Wellness habit evaluation</div>
            <h6>Goals</h6>
            <div className="bullet">• A collaborative, personalized Action Plan</div>
          </div>
          <div className="card">
            <div className="number">2</div>
            <h4>Implement</h4>
            <p><span className="strong">Champion your journey.</span> We're armed with compassion, scientific expertise, 
            and accountability tools to make following your personalized Action Plan as simple as possible.</p>
            <h5>Includes:</h5>
            <h6>Check Ins</h6>
            <div className="bullet">• Identify challenges & strategies</div>
            <div className="bullet">• Action Plan adjustments</div>
            <h6>Personalized App</h6>
            <div className="bullet">• Interactive accountability tracking</div>
            <div className="bullet">• Unlimited text support</div>
            <div className="bullet">• Nutrition information and guides</div>
          </div>
          <div className="card">
            <div className="number">3</div>
            <h4>Achieve</h4>
            <p><span className="strong">Achieve your goals</span> Through hard work, dedication and our adaptive planning, 
            you will build new habits, sustain healthful changes, and advance to new goals.</p>
            <h5>Includes:</h5>
            <h6>Reflection</h6>
            <div className="bullet">• Celebrate the wins</div>
            <div className="bullet">• Identify strategies to maintain your progress</div>
            <h6>Re-Assessment</h6>
            <div className="bullet">• Update recommendations and Action Plan</div>
          </div>
        </div>
        <div className="detail-container">
          <div className="detail-buttons first">
              <p>Per Session</p>
              <p className="strong mb">$180</p>
              <a href="https://buy.stripe.com/eVa7vv2t21sS7hmdQS" 
                  target="blank" className="livesite-schedule">Purchase Session</a>
          </div>
          <div className="detail-buttons">
              <p>10 Pack</p>
              <p className="strong mb">$1,620</p>
              <a href="https://buy.stripe.com/9AQ3ffebK4F4gRW9AH" 
            target="blank">Purchase Pack</a>
          </div>
        </div>
      </div>
      <div className="coaching corp new-serv">
        <h3>Corporate Wellness</h3>
        <div className="cards">
          <div className="card">
            <div className="number">1</div>
            <h4>Plan</h4>
            <p><span className="strong">Build your program.</span> Together we will create the optimal program to meet your group's needs using 
            education, wellness-focused challenges, live Q & A and virtual team workouts.</p>
            <h5>Includes:</h5>
            <h6>Program Development</h6>
            <div className="bullet">• Assess group needs</div>
            <div className="bullet">• Build customized program</div>
          </div>
          <div className="card">
            <div className="number">2</div>
            <h4>Implement</h4>
            <p><span className="strong">Support your team.</span> Your customized program is hosted on our virtual platform so you can 
            bring people together from all over the world. We give you the tools to track progress, send messages, join group-wide wellness challenges 
            and share in each other's success.</p>
            <h5>Includes:</h5>
            <h6>Live Sessions</h6>
            <div className="bullet">• Questions & Answers</div>
            <div className="bullet">• Team workouts</div>
            <h6>Custom App</h6>
            <div className="bullet">• Team leaderboard</div>
            <div className="bullet">• Goal-specific education</div>
            <div className="bullet">• Group chats</div>
          </div>
          <div className="card">
            <div className="number">3</div>
            <h4>Achieve</h4>
            <p><span className="strong">Achieve your shared goals</span> Through hard work, dedication and connection, 
            you will build new habits, sustain healthful changes, and establish a wellness-focused social support system.</p>
            <h5>Includes:</h5>
            <h6>Reflection</h6>
            <div className="bullet">• Celebrate the wins</div>
            <div className="bullet">• Identify strategies to maintain progress</div>
            <h6>Join Our Community</h6>
            <div className="bullet">• Connect and share resources within our online private community</div>
          </div>
        </div>
          <div className="detail-buttons last">
            <p className="mb">Pricing varies</p>
            <a href="mailto:nicole@enriched.health?subject=Corporate Wellness Program" 
              target="blank" class="livesite-contact">Get Started</a>
          </div>
      </div>
      <div className="business">
        <h2>Business Consulting</h2>
        <p>Expert opinions, analyses and recommendations to meet your specific business needs. Whether you want to increase productivity, 
        morale and camaraderie among your employees, create nutrition content to round out your customer experience or are looking for a 
        keynote speaker, we've got you covered!
        </p>
        <h6>Conference and Keynote Speaking</h6>
        <h6>Content Development: Nutrition and Exercise</h6>
        <h6>Scientific Advisory</h6>
        <h6>Media Resource</h6>
        <a href="mailto:nicole@enriched.health?subject=Business Consulting Inquiry" 
          target="blank" class="livesite-contact">Contact Us</a>
      </div>
   </div>
  </>
}

export default About
